/* eslint-disable no-unused-vars */
import {
  updateRequest,
  getRequest,
  postRequest,
  deleteRequest,
} from "@/utils/http";

/**
 * 获取燃尽图
 * @param {Object} parm 参数
 * @returns Promise
 */
export const getBurnoutAsync = (parm = { projectId: "" }) =>
  getRequest(`/project-manage/progress/burnout`, parm);
/**
 * 获取汇总信息
 * @param {Object} parm 参数
 * @returns Promise
 */
export const getSummaryAsync = (parm = { projectId: "" }) =>
  getRequest(`/project-manage/progress/summary`, parm);
