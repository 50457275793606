<template>
  <div class="bgStyle pro-edit">
    <a-tabs size="large" v-model="tabKey">
      <a-tab-pane :tab="projectName" disabled></a-tab-pane>
      <a-tab-pane key="1" tab="项目详情">
        <project-detail
          ref="project"
          @loaded="setProject"
          @userLoaded="setUsers"
        ></project-detail>
      </a-tab-pane>
      <a-tab-pane
        key="2"
        tab="项目任务"
        force-render
        :disabled="taskTabVisible"
      >
        <project-task
          :projectId="projectId"
          :hasRule="hasRule"
          ref="task"
        ></project-task>
      </a-tab-pane>
      <a-tab-pane key="3" tab="项目文件" v-if="projectStatus != 1">
        <ProjectFile :projectId="projectId" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="项目进度" v-if="projectStatus != 1">
        <rate-progress :projectId="projectId"></rate-progress>
      </a-tab-pane>
      <div key="1" slot="tabBarExtraContent">
        <template v-if="tabKey == '1' && projectStatus == 1">
          <a-button
            key="2"
            type="primary"
            @click="onPublic"
            style="margin-right: 16px"
            >发布项目</a-button
          >
        </template>
        <template v-if="tabKey == '2'">
          <label class="labelExtra" @click="sortModal"
            ><a-icon type="cloud" />导入任务</label
          >
          <label @click="showBulkEdit" class="labelExtra"
            ><a-icon type="edit" />批量编辑</label
          >
          <a-checkbox @change="getMyTask"> 仅显示我的任务 </a-checkbox>
          <label @click="showFilter" class="labelExtra"
            ><a-icon type="edit" />筛选</label
          >
        </template>
        <a-badge :count="memberCount" class="tab-badge" @click="onOpenMember"
          ><a-icon type="usergroup-add" class="badge-icon"></a-icon
        ></a-badge>
      </div>
    </a-tabs>
    <project-member-popup
      ref="memberPopup"
      :project="projectObj"
      :users="users"
      :isOwner="userInfo.id == projectObj.ownerId || userInfo.isCompanyAdmin"
    ></project-member-popup>
    <!-- 导入项目任务 -->
    <a-modal
      title="项目任务"
      :visible="sortvisible"
      :confirm-loading="sortconfirmLoading"
      @ok="sorthandleOk"
      @cancel="sorthandleCancel"
      okText="开始上传"
      :cancel-button-props="{ style: { display: 'none' } }"
      width="50%"
    >
      <importProjectTask
        :sortvisible="sortvisible"
        @uploadSuccess="sortSuccess"
      />
    </a-modal>
  </div>
</template>
<script>
import { publicProject } from "@/api/projectmanage.js";
import RateProgress from "./components/RateProgress.vue";
import ProjectDetail from "./components/ProjectDetail.vue";
import ProjectTask from "./components/ProjectTask.vue";
import ProjectMemberPopup from "./components/ProjectMemberPopup.vue";
import ProjectFile from "./components/ProjectDocument.vue";
// 导入项目任务
import importProjectTask from "./components/importProjectTask.vue";
import moment from "moment";
import store from "@/store";
import { addProjectTask } from "@/api/taskManage";
export default {
  components: {
    RateProgress,
    ProjectDetail,
    ProjectTask,
    ProjectMemberPopup,
    ProjectFile,
    importProjectTask,
  },
  data() {
    return {
      projectObj: {},
      projectName: "*",
      projectId: "",
      projectStatus: 1,
      memberCount: 0,
      users: [],
      userInfo: {},
      disabled: false,
      open: false,
      moment,
      tabKey: "1",
      taskTabVisible: false,
      hasRule: false,
      // 导入项目任务
      sortvisible: false,
      sortconfirmLoading: false,
      sortImportData: [],
    };
  },
  created() {
    this.userInfo = store.getters.getUserInfo;
    this.projectId = this.$route.params.id;
    this.tabKey = this.$route.params.tab;
    //超级管理员，有新增计划、编辑权限
    if (this.userInfo.roles != null && this.userInfo.isCompanyAdmin)
      this.hasRule = true;
  },
  mounted() {
    this.$refs.task.getPlans();
  },
  methods: {
    setProject(obj) {
      console.info(obj);
      this.projectName = "项目详情-" + obj.name;
      this.projectStatus = obj.status;
      this.memberCount = obj.members.length;
      this.projectObj = obj;
      //项目负责人，有新增计划、编辑权限
      if (this.projectObj.ownerId == this.userInfo.id) this.hasRule = true;

      this.tabKey = "2";
    },
    setUsers(users) {
      console.info(users);
      this.users = users;
    },
    onPublic() {
      publicProject({ id: this.projectId }).then(() => {
        this.$message.success("项目发布成功");
        this.$router.push("/projectManage");
      });
    },
    onOpenMember() {
      this.$refs.memberPopup.open();
    },
    openMember() {},
    showBulkEdit() {
      if (this.$refs.task.bulkEditForm.taskIds.length == 0) {
        this.$message.error("请先选择任务");
        return;
      }
      this.$refs.task.modalBulkEditVisible = true;
      if (this.$refs.task.members.length == 0)
        this.$refs.task.showMembers(this.$route.params.id);
    },
    //我的任务
    getMyTask(e) {
      var userInfo = store.getters.getUserInfo;
      this.$refs.task.ownerId = e.target.checked ? userInfo.id : null;
      this.$refs.task.getPlans();
    },
    showFilter() {
      this.$refs.task.modalFillterVisible = true;
      if (this.$refs.task.members.length == 0)
        this.$refs.task.showMembers(this.$route.params.id);
    },
    sorthandleOk(e) {
      let _this = this;
      var projectId = _this.projectId;
      this.sortImportData.forEach((el) => {
        el["projectId"] = projectId;
      });
      console.log(this.sortImportData);
      addProjectTask(this.sortImportData)
        .then(function (result) {
          _this.$message.success("添加成功");
          console.log(result);
          _this.sortvisible = false;
          _this.sortconfirmLoading = false;
          _this.$refs.task.getPlans();
        })
        .catch(function (error) {});
    },
    sorthandleCancel(data) {
      this.sortvisible = false;
    },
    //导入项目任务
    sortModal() {
      this.sortvisible = true;
    },
    sortSuccess(data) {
      this.sortImportData = data;
    },
  },
};
</script>
<style lang="scss">
.pro-edit {
  .ant-tabs-nav .ant-tabs-tab-disabled:first-child {
    font-size: 24px;
    color: rgb(51, 51, 51);
  }

  .ant-modal-content {
    .ant-modal-header {
      background-color: rgb(24, 144, 255);
      .ant-modal-title {
        color: white;
      }
    }
    .ant-modal-close-x {
      color: white;
    }
  }
  .tab-badge {
    margin-right: 10px;
    .badge-icon {
      margin-right: 5px;
      font-size: 18px;
    }
  }
  .labelExtra {
    margin-right: 15px;
  }
}
</style>
