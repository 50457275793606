<template>
  <a-card :style="{ overflow: 'auto' }" hoverable>
    <div id="chartProjectTaskBurnout" style="height: 300px"></div>
  </a-card>
</template>

<script>
/* eslint-disable no-unused-vars */
import echarts from "echarts";
import { getBurnoutAsync } from "@/api/projectProgress";
export default {
  name: "ProjectTaskBurnout",
  props: {
    projectId: { type: String, default: () => "" },
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: "任务燃尽图",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["理想剩余任务数", "实际剩余任务数"],
        },
        color:['#65CEFE','#FF6666'],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: false,
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["6-1", "6-2", "6-3", "6-4", "6-8", "6-10", "6-11"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "理想剩余任务数",
            type: "line",
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "实际剩余任务数",
            type: "line",
            data: [220, 182, 191, 234, 290, 330, 310],
          },
        ],
      },
    };
  },
  methods: {
    loadData() {
      getBurnoutAsync({
        projectId: this.projectId,
      }).then((res) => {
        if (Array.isArray(res)) {
          this.loadChart(res);
        } else {
          console.warn("返回数据不匹配！");
        }
      });
    },
    loadChart(data = []) {
      this.chartOptions.xAxis.data = data.map((x) => x.date);
      this.chartOptions.series[0].data = data.map((x) => x.idealLeftCount);
      this.chartOptions.series[1].data = data.map((x) => x.realLeftCount);
      const ele = document.getElementById("chartProjectTaskBurnout");
      if (!ele) {
        return console.warn("无法找到元素 chartProjectTaskBurnout");
      }
      var myChart = echarts.init(ele);
      myChart.setOption(this.chartOptions);
    },
  },
  watch: {
    projectId: {
      handler() {
        if (this.projectId) {
          this.loadData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  updated() {},
};
</script>
