import { render, staticRenderFns } from "./importProjectTask.vue?vue&type=template&id=730a47cc&scoped=true&"
import script from "./importProjectTask.vue?vue&type=script&lang=js&"
export * from "./importProjectTask.vue?vue&type=script&lang=js&"
import style0 from "./importProjectTask.vue?vue&type=style&index=0&id=730a47cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "730a47cc",
  null
  
)

export default component.exports