<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="tasks"
      class="table"
      @change="whenChangePage"
      :pagination="pagination"
      rowKey="id"
      :loading="loading"
      :rowClassName="tableRowClass"
    >
      <a-card slot="filterDropdown">
        <a-input
          placeholder="输入关键字搜索"
          class="searchInput"
          @pressEnter="whenSearch"
          v-model="params.Filter"
        />
        <hr />
        <a-button
          type="primary"
          icon="search"
          size="small"
          class="searchBtn"
          @click="whenSearch"
        >
          搜索
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="whenSearch((params.Filter = null))"
        >
          重置
        </a-button>
      </a-card>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="name" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              :key="i"
              class="highlight"
              >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>
      <div slot="operation" slot-scope="text, record">
        <a @click="whenViewDetail(record)">查看详情</a>
      </div>
      <div slot="statusText" slot-scope="text, record">
        <div
          v-if="record.status === 5 || record.status === '5'"
          class="text-danger"
        >
          {{ text }}
        </div>
        <div v-else>{{ text }}</div>
      </div>
      <a-card slot="filterOfStatus">
        <a-radio-group v-model="params.status">
          <a-radio> 全部 </a-radio>
          <a-radio :value="-1"> 已延期 </a-radio>
          <a-radio :value="0"> 未启动 </a-radio>
          <a-radio :value="5"> 进行中 </a-radio>
          <a-radio :value="8"> 待验收 </a-radio>
          <a-radio :value="10"> 已完成 </a-radio>
        </a-radio-group>
        <hr />
        <a-button
          type="primary"
          icon="search"
          size="small"
          class="searchBtn"
          @click="whenSearch"
        >
          搜索
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="whenSearch((params.status = ''))"
        >
          重置
        </a-button>
      </a-card>
      <div slot="no" slot-scope="text, record, index">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </div>
    </a-table>
    <TaskDetailDialog
      :taskId="taskCtrl.id"
      :visible="taskCtrl.visible"
      @cancel="taskCtrl.visible = false"
      :readonly="true"
      @reloadBord="loadDataAsync"
    />
  </div>
</template>

<script>
import { getTaskBoard } from "@/api/taskManage.js";
import { filterColumns, noFilterColumns } from "./ProjectTaskListColumns";
import TaskDetailDialog from "../TaskDetailDialog.vue";
export default {
  name: "ProjectTaskTable",
  props: {
    showFilter: { type: Boolean, default: () => false },
    projectId: { type: String, default: () => "" },
    taskstatu: { type: Number, default: () => 0 },
  },
  components: {
    TaskDetailDialog,
  },
  data() {
    const columns = this.showFilter ? filterColumns : noFilterColumns;
    return {
      status: 0,
      tasks: [],
      columns: columns,
      loading: false,
      taskCtrl: {
        id: "",
        visible: false,
      },
      /**列表分页参数设置 */
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
      params: {
        status: "",
        projectFilter: 0,
        Filter: null,
        order: null,
        sorting: null,
      },
    };
  },
  methods: {
    tableRowClass(record, index) {
      if (record.statusText === "已延期") {
        return "rowClass";
      }
    },
    loadDataAsync() {
      this.loading = true;
      const query = {
        ProjectId: this.projectId,
        maxResultCount: this.pagination.pageSize,
        skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
        ...this.params,
      };
      getTaskBoard(query)
        .then((res) => {
          this.tasks = res.items;
          this.pagination.total = res.totalCount;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    whenViewDetail(item) {
      this.taskCtrl.id = item.id;
      this.taskCtrl.visible = true;
    },
    whenChangePage(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.params.projectFilter =
        filters.statusText != undefined ? parseInt(filters.statusText[0]) : 0;
      if (sorter) {
        //Object.assign(this.params, sorter);
        this.params.order =
          sorter.order != undefined ? sorter.order : "descend";
        this.params.sorting = sorter.field;
      }
      /*   this.sorting = field;
      this.order = order; */
      this.loadDataAsync();
    },
    whenSearch() {
      this.pagination.current = 1;
      this.loadDataAsync();
    },
  },
  watch: {
    projectId: {
      handler() {
        if (this.showFilter || this.projectId) {
          this.params.projectFilter = this.taskstatu;
          this.loadDataAsync();
        }
      },
      deep: true,
      immediate: true,
    },
    taskstatu: {
      handler() {
        this.params.projectFilter = this.taskstatu;
        this.pagination.current = 1; //切换projectFilter 需把分页设置为第一页，否则会无没查询到数据
        this.loadDataAsync();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}
.searchBtn {
  width: 90px;
  margin-right: 8px;
}
::v-deep .rowClass {
  color: #ff0000;
}
</style>
