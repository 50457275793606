<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 12 }"
    >
      <a-card title="项目详情">
        <a-form-model-item label="项目名称" ref="name" prop="name">
          <a-input v-model="form.name" />
        </a-form-model-item>
        <a-form-model-item label="项目开始日期" prop="startDate">
          <a-date-picker
            v-model="form.startDate"
            :disabled-date="disabledStartDate"
            @openChange="handleStartOpenChange"
            :format="dateFormat"
            :valueFormat="dateFormat"
            placeholder="项目开始日期"
            style="width: 100%"
          />
        </a-form-model-item>
        <a-form-model-item label="项目结束日期" ref="endDate" prop="endDate">
          <a-date-picker
            v-model="form.endDate"
            :disabled-date="disabledEndDate"
            @openChange="handleEndOpenChange"
            :open="open"
            :format="dateFormat"
            :valueFormat="dateFormat"
            placeholder="项目结束日期"
            style="width: 100%"
          />
        </a-form-model-item>
        <a-form-model-item label="项目描述" ref="desc" prop="desc">
          <a-input
            v-model="form.description"
            type="textarea"
            :autoSize="{ minRows: 6, maxRows: 15 }"
            :maxLength=400
          />
        </a-form-model-item>
      </a-card>
      <a-card title="项目成员">
        <a-form-model-item label="负责人" ref="ownerId" prop="ownerId">
          <a-tag color="blue">
            {{ form.ownerName }}
          </a-tag>
          <a-button
            type="link"
            @click="onChangeOwner"
            v-if="formOwnerId == userInfo.id || userInfo.isCompanyAdmin"
          >
            更改负责人
          </a-button>
        </a-form-model-item>
        <a-form-model-item label="成员" ref="members" prop="members">
          <a-tag
            @close="onMemberRemove"
            color="blue"
            v-for="member in form.members"
            :key="member.userId"
          >
            {{ member.userName }}
          </a-tag>
          <a-icon
            type="user-add"
            :style="{ fontSize: '24px', color: 'rgb(61,168,245)' }"
            @click="selectMembers"
            v-if="formOwnerId == userInfo.id || userInfo.isCompanyAdmin"
          />
        </a-form-model-item>
      </a-card>
      <a-space class="footer" v-if="form.status != 3">
        <a-button
          @click="onSubmit"
          v-if="formOwnerId == userInfo.id || userInfo.isCompanyAdmin"
        >
          保存
        </a-button>
        <a-button
          @click="onCreateTemplate"
          v-if="formOwnerId == userInfo.id || userInfo.isCompanyAdmin"
        >
          生成模板
        </a-button>
        <a-button
          @click="onCloseProject"
          v-if="formOwnerId == userInfo.id || userInfo.isCompanyAdmin"
        >
          关闭项目
        </a-button>
      </a-space>
    </a-form-model>

    <!-- 更改项目负责人Modal -->
    <a-modal
      v-model="modalOwnerVisible"
      title="更改项目负责人"
      :width="400"
      centered
      destroyOnClose
    >
      <template slot="footer">
        <a-button
          key="submit"
          type="primary"
          :loading="false"
          @click="ownerSubmit"
        >
          确认
        </a-button>
      </template>
      <a-form-model
        ref="ownerForm"
        :model="ownerForm"
        :rules="ownerRules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="责任人" ref="ownerId" prop="ownerId">
          <a-select v-model="ownerForm.ownerId">
            <a-select-option
              v-for="user in users"
              :key="user.userId"
              :value="user.userId"
              :text="user.fullName"
              @click="onOwnerChange(user)"
              >{{ user.fullName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <project-member
      :targetKeys="targetKeys"
      :users="users"
      ref="member"
      @done="memberCallback"
    ></project-member>
  </div>
</template>
<script>
import moment from "moment";
import { getCurrentCompanyUsersList } from "@/api/companyuser.js";
import {
  getProject,
  updateProject,
  closeProject,
  createTemplate,
} from "@/api/projectmanage.js";
import ProjectMember from "./ProjectMember.vue";
export default {
  components: { ProjectMember },
  name: "ProjectDetail",
  props: {},
  data() {
    return {
      formOwnerId: 0,
      dateFormat: "YYYY-MM-DD",
      form: {
        name: "",
        startDate: "",
        endDate: "",
        description: "",
        ownerId: "",
        ownerName: "",
        members: [],
      },
      rules: {
        name: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          {
            min: 3,
            max: 126,
            message: "项目长度应在3-126字符之间",
            whitespace: true,
            trigger: "blur",
          },
        ],
        startDate: [
          { required: true, message: "请选择项目开始日期", trigger: "change" },
        ],
        endDate: [
          { required: true, message: "请输入项目结束日期", trigger: "change" },
        ],
      },
      open: false,
      userInfo: {},
      users: [],
      targetKeys: [],
      modalOwnerVisible: false,
      ownerForm: {
        ownerId: "",
        ownerName: "",
      },
      ownerRules: {
        ownerId: [
          { required: true, message: "请选择项目负责人", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo;
  },
  mounted() {
    this.getProject();
    this.getCurrentCompanyUsersList();
  },
  methods: {
    getProject() {
      getProject(this.$route.params.id).then((res) => {
        this.form = res;
        this.formOwnerId = this.form.ownerId;
        this.form.startDate =
          res.startDate != null ? moment(res.startDate, "YYYY-MM-DD") : "";
        (this.form.endDate == res.endDate) != null
          ? moment(res.endDate, "YYYY-MM-DD")
          : "";
        for (let m of res.members) {
          this.targetKeys.push(m.userId);
        }
        this.$emit("loaded", res);
      });
    },
    getCurrentCompanyUsersList() {
      getCurrentCompanyUsersList({ isFilterInvitationStatus: true ,SkipCount:0,MaxResultCount:500}).then(
        (res) => {
          this.users = [];
          res.items.forEach((item) => {
            item.key = item.userId.toString();
            item.title = item.fullName;
            item.disabled = this.form.ownerId === item.userId;
            this.users.push(item);
          });
          this.$emit("userLoaded", this.users);
        }
      );
    },
    disabledStartDate(startValue) {
      const endValue = this.form.endDate;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.form.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.open = true;
      }
    },
    handleEndOpenChange(open) {
      this.open = open;
    },
    onSubmit() {
      if (this.ownerForm.ownerId !== "") {
        this.form.ownerId = this.ownerForm.ownerId;
        this.form.ownerName = this.ownerForm.ownerName;

        let _has = false;
        for (let i = 0; i < this.form.members.length; i++) {
          if (this.form.members[i].isOwner) {
            this.form.members[i].isOwner = false;
          }

          if (this.form.members[i].userId == this.ownerForm.ownerId) {
            this.form.members[i].isOwner = true;
            _has = true;
          }
        }

        if (!_has) {
          this.form.members.push({
            userId: this.ownerForm.ownerId,
            userName: this.ownerForm.ownerName,
            isOwner: true,
          });
        }
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          updateProject(this.form)
            .then((res) => {
              if (res && res.id != "") {
                this.$message.success("更新项目成功");
                this.formOwnerId = this.form.ownerId;
              }
            })
            .catch(() => {
              this.$message.error("更新项目失败");
            });
        } else {
          return false;
        }
      });
    },
    onCloseProject() {
      let _this = this;
      this.$confirm({
        title: "确定",
        content: "关闭当前项目",
        okText: "关闭",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          closeProject({ id: _this.$route.params.id }).then(() => {
            _this.$message.success("项目已关闭");
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    selectMembers() {
      this.$refs.member.openModal();
    },
    ownerSubmit() {
      this.form.ownerId = this.ownerForm.ownerId;
      this.form.ownerName = this.ownerForm.ownerName;

      let _has = false;
      for (let i = 0; i < this.form.members.length; i++) {
        if (this.form.members[i].isOwner) {
          this.form.members[i].isOwner = false;
        }

        if (this.form.members[i].userId == this.ownerForm.ownerId) {
          this.form.members[i].isOwner = true;
          _has = true;
        }
      }

      if (!_has) {
        this.form.members.push({
          userId: this.ownerForm.ownerId,
          userName: this.ownerForm.ownerName,
          isOwner: true,
        });
      }
      this.modalOwnerVisible = false;
    },
    memberCallback(data) {
      this.form.members = [];
      for (let user of data) {
        this.form.members.push({
          userId: user.userId,
          userName: user.fullName,
          isOwner: user.disabled,
        });
      }
    },
    onCreateTemplate() {
      createTemplate({ id: this.$route.params.id }).then(() => {
        this.$message.success("项目模板生成成功");
      });
    },
    onChangeOwner() {
      this.modalOwnerVisible = true;
      this.ownerForm.ownerId = this.form.ownerId;
    },
    onOwnerChange(value) {
      this.ownerForm.ownerName = value.fullName;
    },
    onMemberRemove(e) {
      console.info(e);
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
