<template>
  <div>
    <div class="top">
      <span> 基本信息 </span>
      <a @click="edit" v-if="editable && !isEdit">编辑</a>
    </div>
    <a-row class="l-row">
      <a-col :span="4"> 文档名称: </a-col>
      <a-col :span="20">
        <div v-if="!isEdit" class="docname">
          {{ objData.docName }}
        </div>
        <a-input v-model="objData.docName" v-else style="width: 66%"></a-input>
      </a-col>
    </a-row>
    <a-row class="l-row">
      <a-col :span="24">
        文档编码:
        <span>{{ objData.docCode }}</span>
      </a-col>
    </a-row>
    <a-row class="l-row">
      <a-col :span="12">
        图文档版本:
        <span>{{ objData.docVersion }}</span>
      </a-col>
      <a-col :span="12">
        文档类型:
        <span v-if="!isEdit">{{ filterType(objData.docType) }}</span>
        <a-select v-model="objData.docType" v-else style="width: 56%">
          <a-select-option
            v-for="item in arrDocTypes"
            :value="item.id"
            :key="item.id"
          >
            {{ item.codeName }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row class="l-row">
      <a-col :span="12">大小: {{ objData.docSize }}KB</a-col>
      <a-col :span="12">文档格式: {{ objData.docFormat }}</a-col>
    </a-row>
    <a-row class="l-row">
      <a-col :span="12">上传人: {{ objData.docUploader }}</a-col>
      <a-col :span="12" :title="objData.creationTime | filterDate">
        上传时间: {{ objData.creationTime | filterDate }}</a-col
      >
    </a-row>
    <a-row class="l-row">
      <a-col :span="12"> 最后修改人: {{ objData.docLastModifierName }}</a-col>
      <a-col :span="12" :title="objData.docLastModificationTime | filterDate">
        修改时间:
        {{ objData.docLastModificationTime | filterDate }}</a-col
      >
    </a-row>
    <div v-if="editable && isEdit" class="btn">
      <a-button type="primary" @click="save">保存</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentBasicInfo",
  props: {
    isEdit: { type: Boolean, default: () => false },
    editable: { type: Boolean, default: () => false },
    objData: { type: Object, default: () => {} },
    arrDocTypes: { type: Array, default: () => [] },
    objDocType: { type: Object, default: () => {} },
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  methods: {
    save() {
      this.$emit("save");
    },
    edit() {
      this.$emit("edit");
    },
    filterType(val) {
      if (!val) return "";
      const objDocType = this.objDocType || {};
      return objDocType[val] || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 0 2px 6px;
  span {
    font-size: 22px;
    font-weight: bold;
    i {
      cursor: pointer;
    }
  }
}

.l-row {
  line-height: 66px;
  font-size: 14px;
  > .ant-col {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.btn {
  margin-top: 12px;
}

.docname {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
}
</style>
