<template>
  <div>
    <a-card>
      <a-input-search
        placeholder="请输入文件名或任务名搜索内容"
        style="width: 226px"
        v-model="filter"
        @search="whenSearch"
        :allowClear="true"
      />
      <br />
      <a-table
        :columns="columns"
        :data-source="dataSource"
        class="table"
        rowKey="id"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <div class="operate-container" slot="action" slot-scope="text, record">
          <a @click="whenViewRow(record)" class="btn-link">
            <a-icon type="search"></a-icon>
          </a>
          <a @click="whenEditRow(record)" class="btn-link">
            <a-icon type="edit"></a-icon>
          </a>
          <a @click="whenDeleteRow(record)" class="btn-link">
            <a-icon type="delete"></a-icon>
          </a>
        </div>
      </a-table>
    </a-card>
    <a-modal
      :visible="doc.visible"
      :width="1400"
      :destroyOnClose="true"
      @cancel="doc.visible = false"
      :ok-button-props="{ style: { display: 'none' } }"
    >
      <Document
        :id="doc.docItemId"
        isFrom="historyModal"
        :figureDocumentId="doc.docId"
        :edit="doc.edit"
        :isDialog="true"
        @change="loadDataAsync"
      />
    </a-modal>
  </div>
</template>

<script>
import columns from "./ProjectDocumentColumns";
import { getProjectDocumentsAsync } from "@/api/projectmanage.js";
import { deleteRefrence } from "@/api/taskManage.js";
import Document from "@/views/FigureDocManage/Detail.vue";
export default {
  name: "ProjectFile",
  components: { Document },
  props: {
    projectId: { type: String, default: () => "" },
  },
  data: () => {
    return {
      columns: columns,
      dataSource: [],
      filter: undefined,
      loading: false,
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
      doc: {
        docId: undefined,
        docItemId: undefined,
        edit: false,
        visible: false,
      },
    };
  },
  created() {
    this.loadDataAsync();
  },
  methods: {
    whenViewRow(row) {
      Object.assign(this.doc, row);
      this.doc.edit = false;
      this.doc.visible = true;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    whenEditRow(row) {
      Object.assign(this.doc, row);
      this.doc.edit = true;
      this.doc.visible = true;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    whenDeleteRow(row) {
      this.$confirm({
        title: "确定",
        content: `将${row.docName}从任务${row.taskName}中移除？`,
        okText: "删除",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          this.loading = true;
          deleteRefrence(row.id).then(() => {
            this.loading = false;
            this.$message.success("已成功移除文档！");
            this.loadDataAsync();
          });
        },
        onCancel() {},
      });
    },
    loadDataAsync() {
      if (!this.projectId) return;
      this.loading = true;
      const params = {
        filter: this.filter,
        skipCount: this.skip,
        maxResultCount: this.pagination.pageSize,
        projectId: this.projectId,
      };
      getProjectDocumentsAsync(params).then((res) => {
        this.loading = false;
        this.dataSource = res.items;
        this.pagination.total = res.totalCount;
      });
    },
    whenSearch() {
      this.pagination.current = 1;
      this.loadDataAsync();
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.pagination = pager;
      this.loadDataAsync();
    },
  },
  computed: {
    skip() {
      return (this.pagination.current - 1) * this.pagination.pageSize;
    },
  },
};
</script>

<style lang="scss" scoped>
.bgStyle + .bgStyle {
  margin-top: 1rem;
}
.operate-container {
  .btn-link {
    font-size: larger;
    margin-right: 10px;
  }
}
</style>
