<template>
  <a-card :style="{ overflow: 'auto' }" hoverable>
    <div id="chartProjectTaskSummary" style="height: 300px"></div>
  </a-card>
</template>

<script>
/* eslint-disable no-unused-vars */
import echarts from "echarts";
import { getSummaryAsync } from "@/api/projectProgress";
export default {
  name: "ProjectTaskSummary",
  props: {
    projectId: { type: String, default: () => "" },
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: "任务漏斗图",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}",
        },
        toolbox: false,
        legend: {
          //data: ["总任务数", "已分配任务数", "已完成任务数"],
          bottom: "5%",
        },
        color: ["#3AA1FF", "#4ECB73", "#FBD437"],
        series: [
          {
            name: "漏斗图",
            type: "funnel",
            left: "10%",
            top: 60,
            //x2: 80,
            bottom: 60,
            width: "80%",
            // height: {totalHeight} - y - y2,
            min: 0,
            max: 100,
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 2,
            label: {
              show: true,
              position: "inside",
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            emphasis: {
              label: {
                fontSize: 15,
              },
            },
            data: [
              { value: 60, name: "总任务数" },
              { value: 40, name: "已分配任务数" },
              { value: 20, name: "已完成任务数" },
            ],
          },
        ],
      },
    };
  },
  methods: {
    loadData() {
      getSummaryAsync({
        projectId: this.projectId,
      }).then((res) => {
        if (res.totalCount) {
          this.loadChart(res);
        } else {
          console.warn("返回数据不匹配！");
        }
      });
    },
    loadChart(
      data = {
        totalCount: 2,
        assignedCount: 2,
        finishedCount: 0,
      }
    ) {
      const options = this.chartOptions.series[0].data;
      options[0].value = data.totalCount;
      options[1].value = data.assignedCount;
      options[2].value = data.finishedCount;
      const ele = document.getElementById("chartProjectTaskSummary");
      if (!ele) {
        return console.warn("无法找到元素 chartProjectTaskSummary");
      }
      var myChart = echarts.init(ele);
      myChart.setOption(this.chartOptions);
    },
  },
  watch: {
    projectId: {
      handler() {
        if (this.projectId) {
          this.loadData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
