<template>
  <div ref="chart" class="container" id="container"></div>
</template>
<script>
import echarts from "echarts";
export default {
  data() {
    return {};
  },
  props: {
    datasources: Array,
  },
  watch: {
    datasources: {
      handler() {
        console.log(this.datasources);
        this.getColumnar();
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    getColumnar() {
      let myChart = echarts.init(document.getElementById("container"));
      var xAxisData = [];
      var data1 = [];
      var data2 = [];
      var option;
      var columnarList = this.datasources;
      if (columnarList != undefined) {
        columnarList.forEach(function (item, index) {
          xAxisData.push(item.userName);

          data1.push(item.normalCount); //正常
          data2.push(item.delayCount); //延期
        });
      }

      var emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(0,0,0,0.3)",
        },
      };
      option = {
        color: ["#FF6666", "#2D8CF0"],
        legend: {
          data: ["正常", "延期"],
          left: "center",
          textStyle: {
            color: "#696969",
          },
          bottom: "10%",
        },
        brush: {
          //toolbox: ["rect", "polygon", "lineX", "lineY", "keep", "clear"],
          // xAxisIndex: 0,
        },
        toolbox: {
          show: false,
          feature: {
            magicType: {
              //type: ["stack", "tiled"],
            },
            dataView: {},
          },
        },
        tooltip: {},
        xAxis: {
          data: xAxisData,
          name: "X Axis",
          axisLabel: {
            // 设置X轴节点标题倾斜角度
            rotate: 20,
            formatter: function (value) {
              var res = value;
              if (res.length > 5) {
                res = res.substring(0, 4) + "..";
              }
              return res;
            },
          },
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false },
        },
        yAxis: [
          {
            type: "value",
            axisLine: { show: true, lineStyle: { color: "#ccc" } },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
            },
            min: 0,
            max: 25,
            splitNumber: 6,
          },
        ],
        grid: {
          bottom: 100,
        },
        series: [
          {
            name: "延期",
            type: "bar",
            stack: "one",
            barWidth:20,
            emphasis: emphasisStyle,
            data: data2,
            itemStyle: {
              normal: {
                color: function () {
                  var colorList = "#FF6666";
                  return colorList;
                },
              },
            },
          },
          {
            name: "正常",
            type: "bar",
            stack: "one",
            barWidth:20,
            emphasis: emphasisStyle,
            data: data1,
            itemStyle: {
              normal: {
                color: function () {
                  var colorList = "#2D8CF0";
                  return colorList;
                },
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
  mounted() {},
};
</script>
