<template>
  <div class="gutter">
    <a-row :gutter="16" style="margin-bottom:20px">
      <a-col class="gutter-row" :span="14">
        <div class="gutter-box">
          <BurnoutSummary :projectId="projectId" />
        </div>
      </a-col>
      <a-col class="gutter-row" :span="10">
        <div class="gutter-box">
          <TotalSummary :projectId="projectId" />
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="16" style="margin-bottom:20px">
      <a-col class="gutter-row" :span="14">
        <div class="gutter-box">
          <a-card style="overflow: hidden" hoverable>
            <a-row>
              <a-col :span="24">
                <div class="title">
                  <span>任务总览</span><span>任务总数</span>：<span>{{ tasks.allCount }}</span>
                </div>
              </a-col>
              <a-col :span="12">
                <!--    <div id="pie1Dom" style="height: 300px"></div> -->
                <TaskOverViewPie
                  :pieData="pieData"
                  id="pie1Dom"
                  :pieId="pieDataId"
                  :titles="pie1DataTitles"
                  :pieIndex=1
                  style="height: 300px"
                />
              </a-col>
              <a-col :span="12">
                <!--  <div id="pie2Dom" style="height: 300px"></div> -->
                <TaskOverViewPie2
                  :pieData="pieData2"
                  id="pie2Dom"
                  :pieId="pieDataId2"
                  :titles="pie2DataTitles"
                  :pieIndex=2
                  style="height: 300px"
                />
              </a-col>
            </a-row>
          </a-card>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="10">
        <div class="gutter-box">
          <a-card style="overflow: hidden" hoverable>
            <a-row>
              <a-col :span="24">
                <div class="title">
                  <span>任务责任人</span>
                </div>
              </a-col>
              <a-col :span="24">
                <TaskResponsibilityColumnar
                  :datasources="datasources"
                  style="height: 300px"
                />
              </a-col>
            </a-row>
          </a-card>
        </div>
      </a-col>
    </a-row>
    <TaskList :projectId="projectId" />
  </div>
</template>
<script>
import TaskList from "@/views/TaskManage/ProjectTaskList.vue";
import BurnoutSummary from "@/views/TaskManage/components/BurnoutSummary.vue";
import TotalSummary from "@/views/TaskManage/components/TotalSummary.vue";
import { getTaskOverView } from "@/api/projectmanage.js";
import TaskOverViewPie from "@/components/TaskOverViewPie";
import TaskOverViewPie2 from "@/components/TaskOverViewPie";
import TaskResponsibilityColumnar from "@/components/TaskResponsibilityColumnar";
export default {
  name: "RateProgress",
  components: {
    TaskList,
    TotalSummary,
    BurnoutSummary,
    TaskOverViewPie,
    TaskOverViewPie2,
    TaskResponsibilityColumnar,
  },
  props: {
    name: {
      type: String,
      default: "项目进度",
    },
    projectId: { type: String, default: () => "" },
  },
  data() {
    return {
      tasks: {},
      data: [],
      pie1DataTitles: ["未启动","进行中", "待验收", "已完成"],
      pie2DataTitles: ["正常", "延期"],
      pieDataId2: "pie2Dom",
      pieDataId: "pie1Dom",
      pieData: [
        {
          value: 0,
          name: "未启动",
        },
        {
          value: 0,
          name: "进行中",
        },
        {
          value: 0,
          name: "待验收",
        },
        {
          value: 0,
          name: "已完成",
        },
      ],
      pieData2: [
        { value: 0, name: "正常" },
        { value: 0, name: "延期" },
      ],
      datasources: [],
    };
  },
  mounted() {
    this.getTaskOverView();
    this.initPie();
    this.initBar();
  },
  methods: {
    getTaskOverView() {
      getTaskOverView({ id: this.$route.params.id }).then((res) => {
        this.tasks = res;
        this.initPie();
      });
    },
    initPie() {
      this.pieData[0]["value"] = this.tasks.unallocatedCount;
      this.pieData[1]["value"] = this.tasks.onDoingCount; 
      this.pieData[2]["value"] = this.tasks.waitingAcceptCount;      
      this.pieData[3]["value"] = this.tasks.doneCount;

      this.pieData2[0]["value"] = this.tasks.normalCount;
      this.pieData2[1]["value"] = this.tasks.delayCount;

      this.datasources = this.tasks.owners;
    },
    initBar() {},
    onDetail(record) {
      console.info(record);
    },
  },
};
</script>
<style lang="scss" scoped>
  .title{
    margin-bottom: 10px;

    span:nth-child(1){
      font-size: 20px;
      font-weight: bold;
      color: #000000;
      margin-right: 20px;
    }
    
    span:nth-child(2){
      font-size: 16px;
    }

    span:nth-child(3){
      font-size: 16px;
      color: #ff9900;
    }
  }
</style>
