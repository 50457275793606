import moment from "moment";
export const noFilterColumns = [
  {
    title: "序号",
    dataIndex: "id",
    scopedSlots: { customRender: "no" },
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    ellipsis: true,
  },
  {
    title: "计划名称",
    dataIndex: "planName",
    ellipsis: true,
  },
  {
    title: "任务名称",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "预计开始日期",
    dataIndex: "startDate",
  },
  {
    title: "预计结束日期",
    dataIndex: "endDate",
  },
  {
    title: "预计工期",
    dataIndex: "workDays",
  },
  {
    title: "任务状态",
    dataIndex: "statusText",
    scopedSlots: { customRender: "statusText" },
  },
  {
    title: "责任人",
    dataIndex: "ownerName",
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
export const filterColumns = [
  {
    title: "序号",
    // dataIndex: "number",
    align: "center",
    // sorter: true,
    scopedSlots: { customRender: "no" },
    // customRender: (t, r, index) => {
    //   return parseInt(
    //     `${
    //       (this.pagination.current - 1) * this.pagination.pageSize + index + 1
    //     }`
    //   );
    // },
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    align: "center",
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    ellipsis: true,
  },
  {
    title: "计划名称",
    dataIndex: "planName",
    align: "center",
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    ellipsis: true,
  },
  {
    title: "任务名称",
    dataIndex: "name",
    align: "center",
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender",
    },
    ellipsis: true,
  },
  {
    title: "预计开始时间",
    dataIndex: "startDate",
    align: "center",
    sorter: true,
    customRender: (text) => {
      if (!text) return "---";
      return moment(text).format("YYYY-MM-DD");
    },
  },
  {
    title: "预计结束时间",
    dataIndex: "endDate",
    align: "center",
    sorter: true,
    customRender: (text) => {
      if (!text) return "---";
      return moment(text).format("YYYY-MM-DD");
    },
  },
  {
    title: "预计工期",
    align: "center",
    dataIndex: "workDays",
    sorter: true,
  },
  {
    title: "任务状态",
    dataIndex: "statusText",
    align: "center",
    scopedSlots: { filterDropdown: "filterOfStatus" },
    // filters: [
    //   { text: "已结束", value: "2" },
    //   { text: "已开始", value: "0" },
    //   { text: "进行中", value: "1" },
    // ],
    //onFilter: (value, record) => record.status.indexOf(value) === 1,
  },
  {
    title: "责任人",
    align: "center",
    dataIndex: "ownerName",
    customRender: (text) => {
      if (!text) return "---";return text;       
    },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  filterColumns: filterColumns,
  noFilterColumns: noFilterColumns,
};
