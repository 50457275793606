export default [
  {
    title: "文件名称",
    dataIndex: "docName",
    ellipsis: true,
    customRender: (text, record) => `${text}.${record.docFormart}`,
  },
  {
    title: "版本号",
    dataIndex: "docVersion",
  },
  {
    title: "所属计划",
    dataIndex: "planName",
    ellipsis: true,
  },
  {
    title: "所属任务",
    dataIndex: "taskName",
    ellipsis: true,
  },
  {
    title: "物料编码",
    dataIndex: "materialCode",
    ellipsis: true,
  },
  {
    title: "物料名称",
    dataIndex: "materialName",
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
