<template>
  <div>
    <!-- 项目成员 -->
    <a-modal
      v-model="visible"
      title="项目成员"
      :width="600"
      :footer="null"
      centered
      destroyOnClose
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="负责人">
          <a-tag color="blue"> {{ project.ownerName }} </a-tag>
          <a-button type="link" @click="onTransmit" v-if="isOwner"
            >转交</a-button
          >
        </a-form-item>
        <a-form-item label="成员">
          <a-tag
            color="blue"
            v-for="member in project.members"
            :key="member.userId"
          >
            {{ member.userName }}
          </a-tag>
          <a-icon
            type="user-add"
            :style="{ fontSize: '24px', color: 'rgb(61,168,245)' }"
            @click="onSelectMembers"
            v-if="isOwner"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 更改项目负责人Modal -->
    <a-modal
      v-model="modalOwnerVisible"
      title="更改项目负责人"
      :width="400"
      centered
      destroyOnClose
    >
      <template slot="footer">
        <a-button
          key="submit"
          type="primary"
          :loading="false"
          @click="onOwnerSubmit"
        >
          确认
        </a-button>
      </template>
      <a-form-model
        ref="ownerForm"
        :model="ownerForm"
        :rules="ownerRules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="责任人" ref="ownerId" prop="ownerId">
          <a-select v-model="ownerForm.ownerId" @select="onOwnerChange">
            <a-select-option
              v-for="user in users"
              :key="user.userId"
              :value="user.userId"
              :text="user.fullName"
              >{{ user.fullName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <project-member
      :targetKeys="targetKeys"
      :users="users"
      ref="member"
      @done="memberCallback"
    ></project-member>
  </div>
</template>
<script>
import ProjectMember from "./ProjectMember.vue";
import { updateProject } from "@/api/projectmanage.js";
export default {
  components: { ProjectMember },
  name: "ProjectMemberPopup",
  props: {
    project: {},
    users: [],
    isOwner: Boolean,
  },
  data() {
    return {
      visible: false,
      modalOwnerVisible: false,
      targetKeys: [],
      ownerForm: {
        ownerId: "",
      },
      ownerRules: {
        ownerId: [
          { required: true, message: "请选择项目负责人", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    onSelectMembers() {
      this.$refs.member.openModal();
      for (let m of this.project.members) {
        this.targetKeys.push(m.userId);
      }
      console.info(this.targetKeys);
    },
    memberCallback(data) {
      this.project.members = [];
      for (let user of data) {
        this.project.members.push({
          userId: user.userId,
          userName: user.fullName,
          isOwner: user.disabled,
        });
      }

      updateProject(this.project).then(() => {
        this.$message.success("项目成员更新成功");
      });
    },
    onOwnerSubmit() {
      updateProject(this.project).then(() => {
        this.$message.success("项目转交成功");
        this.visible = false;
        this.modalOwnerVisible=false;
      });
    },
    onTransmit() {
      this.modalOwnerVisible = true;
      this.ownerForm.ownerId = this.project.ownerId;
    },
    onOwnerChange(value, option) {
      this.project.ownerId = value;
      this.project.ownerName = option.data.attrs["text"];

      let _has = false;
      for (let i = 0; i < this.project.members.length; i++) {
        if (this.project.members[i].isOwner) {
          this.project.members[i].isOwner = false;
        }

        if (this.project.members[i].userId == value) {
          this.project.members[i].isOwner = true;
          _has = true;
        }
      }

      if (!_has) {
        this.project.members.push({
          userId: value,
          userName: option.data.attrs["text"],
          isOwner: true,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
