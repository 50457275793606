<template>
  <a-modal
    :visible="visible"
    :title="title"
    :footer="null"
    :destroyOnClose="true"
    :readonly="readonly"
    @cancel="$emit('cancel')"
    :width="1390"
  >
    <Content :taskId="taskId" @triggerBorder="loadDataAsync" />
  </a-modal>
</template>

<script>
import Content from "./components/TaskDetailContent.vue";
export default {
  props: {
    visible: { type: Boolean, default: () => false },
    taskId: { type: String, default: () => "" },
    title: { type: String, default: () => "查看详情" },
    readonly: { type: Boolean, default: () => false },
  },
  components: {
    Content,
  },
  methods: {
    loadDataAsync() {
      this.$emit("reloadBord");
    },
  },
};
</script>

<style></style>
