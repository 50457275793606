<template>
  <a-card style="overflow: hidden" hoverable title="任务列表">
    <ProjectTaskTable :showFilter="true" :projectId="projectId" />
  </a-card>
</template>
<script>
/* eslint-disable no-unused-vars */
import ProjectTaskTable from "./components/ProjectTaskTable.vue";
export default {
  name: "ProjectTaskList",
  components: {
    ProjectTaskTable,
  },
  props: {
    projectId: { type: String, default: () => "" },
    name: {
      type: String,
      default: () => "项目进度",
    },
  },
  data() {
    return {};
  },
};
</script>
