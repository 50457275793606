<template>
  <div id="pieone"></div>
</template>
<script>
import echarts from "echarts";
export default {
  props: {
    pieData: Array,
    pieId: String,
    titles: Array,
    pieIndex:Number,
  },
  data() {
    return {};
  },
  watch: {
    pieData: {
      handler() {
        console.log(this.pieData);
        this.getPie();
      },
      deep: true,
      immediate: false,
    },
    pieId: {
      handler() {
        this.getPie();
      },
    },
    titles: {
      handler() {
        this.getPie();
      },
    },
  },
  mounted() {
    this.getPie();
  },
  methods: {
    getPie() {
      var myChartone = echarts.init(document.getElementById(this.pieId));
      var colorArray=[];
      if(this.pieIndex===1){
        colorArray=['#3AA1FF','#36CBCB','#4ECB73','#FBD437'];
      }else{
        colorArray=["#3AA1FF","#FF6666"];
      }

      var optionone;
      optionone = {
        title: {
          // text: '天气情况统计',
          // subtext: '虚构数据',
          // left: 'center'
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          bottom: 10,
          left: "center",
          data: this.titles,
        },
        color:colorArray,
        series: [
          {
            name: "",
            type: "pie",
            radius: "60%",
            center: ["50%", "50%"],
            selectedMode: "single",
            data: this.pieData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      optionone && myChartone.setOption(optionone);
    },
  },
};
</script>
