<template>
  <div>
    <div class="bgStyle wrapper">
      <div class="head">
        <a-space :size="68">
          <a @click="uploadOpen" v-if="edit">
            <a-icon type="upload" /> 上传新版
          </a>
          <a :href="objData.docUrl" @click="handleDownload">
            <a-icon type="download" /> 下载
          </a>
          <a @click="historyVersion"><a-icon type="history" />历史版本</a>
          <!-- <a><a-icon type="printer" />打印</a> -->
          <a @click="toLog"><a-icon type="file" />操作日志</a>
        </a-space>
        <a-button type="primary" class="back" @click="toBack" v-if="!isDialog">
          返回
        </a-button>
      </div>
    </div>
    <div class="bgStyle table wrapper">
      <div class="left table-cell" :style="leftStyle">
        <a
          class="left-collapse collapse"
          @click="setLeftCollapse"
          v-if="!isDialog"
        >
          <a-icon :type="leftIcon"></a-icon>
        </a>
        <PreviewDoc :doc="objData" @refresh="getVersionData" />
        <a class="right-collapse collapse" @click="setRightCollapse">
          <a-icon :type="rightIcon"></a-icon>
        </a>
      </div>
      <div class="right table-cell" :style="rightStyle">
        <BasicInfo
          :isEdit="isEdit"
          :editable="edit"
          :objData="objData"
          :arrDocTypes="arrDocTypes"
          :objDocType="objDocType"
          @save="save"
          @edit="whenEdit"
        />
      </div>
    </div>
    <a-modal
      v-model="visibleHistory"
      title="历史版本"
      :width="1000"
      :maskClosable="false"
      :destroyOnClose="true"
      :footer="null"
    >
      <history-version
        :figureDocumentId="figureDocumentId"
        :id="itemId"
        :currentPage="true"
        @toDetailCurrent="toDetailCurrent"
      />
    </a-modal>

    <a-modal
      v-model="visibleNew"
      title="上传新版"
      :width="1200"
      :maskClosable="false"
      :destroyOnClose="true"
      :cancel-button-props="{ style: { display: 'none' } }"
      :ok-button-props="{ style: { display: 'none' } }"
    >
      <update-doc
        ref="refUpdateDoc"
        :figureDocumentId="figureDocumentId"
        @uploadOk="whenUploadOk"
      />
    </a-modal>
  </div>
</template>

<script>
import OSS from "ali-oss";
import HistoryVersion from "./components/HistoryVersion.vue";
import PreviewDoc from "@/components/Preview/Index.vue";
import {
  getById,
  getVersionData,
  update,
  uploadNewVersion,
  getByCodeKeyPath,
  addDownLoadFigureDocOperateLog,
  getAllFigureDocTypes,
  getAliyunOssSettings,
} from "@/api/figureDocument.js";
import UpdateDoc from "./components/UpdateDoc.vue";
import BasicInfo from "./components/BasicInfo.vue";
import { findLeftBar } from "@/utils/common.js";
export default {
  name: "DocumentDetail",
  components: {
    HistoryVersion,
    UpdateDoc,
    BasicInfo,
    PreviewDoc,
  },
  props: {
    id: { type: String, default: () => "" },
    edit: { type: [Boolean, String], default: () => false },
    isDialog: { type: [Boolean, String], default: () => false },
    isFrom: { type: String, default: () => "list" },
    figureDocumentId: { type: String, default: () => "" },
  },
  data() {
    return {
      isHiddenInfomation: false,

      visibleHistory: false,
      visibleNew: false,

      objDocType: {},
      arrDocTypes: [],
      objData: {},
      itemId: this.id,
      isCurrentPage: this.isFrom,
      isEdit: false,
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  created() {
    this.getDocArr();
    this.initRender();
  },
  methods: {
    initRender() {
      // 图文档之外的模块跳进来 other-list  other-historyModal
      if (this.isCurrentPage == "list" || this.isCurrentPage == "other-list") {
        //this.getData();
        this.getVersionData();
      } else if (
        this.isCurrentPage == "historyModal" ||
        this.isCurrentPage == "other-historyModal"
      ) {
        this.getVersionData();
      } else {
        //this.getData();
        this.getVersionData();
      }
    },

    toDetailCurrent(params) {
      console.log("toDetailCurrent", params);
      this.itemId = params.id;
      this.isCurrentPage = "historyModal";

      this.visibleHistory = false;
      this.initRender(params);
    },

    getData() {
      let figureDocumentId = this.figureDocumentId;
      getById(figureDocumentId).then((res) => {
        console.log("getData", res);

        this.itemId = res.id;
        this.objData = res || {};
      });
    },

    getVersionData() {
      let id = this.itemId;
      getVersionData(id).then((res) => {
        console.log("getVersionData", res);

        this.objData = res || {};
      });
    },
    save() {
      console.log("save", this.objData);
      let params = {
        figureDocumentItemId: this.objData.id,
        docName: this.objData.docName,
        docType: this.objData.docType,
      };

      update(params)
        .then(() => {
          this.$message.success("保存成功");
          this.$emit("change");
          this.initRender();
        })
        .catch(() => {
          this.isEdit = true;
        });

      this.isEdit = false;
    },

    uploadOpen() {
      this.visibleNew = true;
    },

    handleDownload() {
      getByCodeKeyPath("HS_TWD_CZLX_XZ").then((res) => {
        let params = {
          figureDocumentId: this.figureDocumentId,
          operateType: res.id,
        };
        addDownLoadFigureDocOperateLog(params);
        this.$message.success("下载成功!");
      });
    },

    //开始上传
    handleUpload() {
      let fileDataSource = this.$refs.refUpdateDoc.dataSource;

      if (fileDataSource.length == 0) {
        this.$message.error("请先选择文件，再进行上传！");
      } else {
        let figureDocumentId = this.figureDocumentId;
        let dataObj = this.$refs.refUpdateDoc.dataSource[0];
        console.log("handleUpload", dataObj);

        this.loading = true;

        var fileExtension = dataObj.fileExtension; //文件后缀名

        var fileGuid = this.newGuid(); // 生成文件名
        var aliyunFullPath =
          this.aliyunOssSettingDto.projectName +
          "/" +
          this.aliyunOssSettingDto.fileDir +
          "/" +
          this.getNowDate() +
          "/" +
          fileGuid +
          fileExtension; //存储位置

        var client = new OSS({
          region: this.aliyunOssSettingDto.region,
          accessKeyId: this.aliyunOssSettingDto.accessKeyId,
          accessKeySecret: this.aliyunOssSettingDto.accessKeySecret,
          bucket: this.aliyunOssSettingDto.bucketName_HardWareSaas,
          secure: true,
          endpoint: this.aliyunOssSettingDto.endpoint,
        });

        client
          .multipartUpload(aliyunFullPath, dataObj.file)
          .then((result) => {
            var fileOssLink = result.res.requestUrls[0];
            if (fileOssLink.indexOf("?") > 0) {
              fileOssLink = fileOssLink.substr(0, fileOssLink.indexOf("?"));
            }
            var filePreviewLink = "";
            if (
              fileExtension.toLowerCase() == ".pptx" ||
              fileExtension.toLowerCase() == ".ppt" ||
              fileExtension.toLowerCase() == ".docx" ||
              fileExtension.toLowerCase() == ".doc" ||
              fileExtension.toLowerCase() == ".xlsx" ||
              fileExtension.toLowerCase() == ".xls"
            ) {
              filePreviewLink =
                "http://view.officeapps.live.com/op/view.aspx?src=" +
                fileOssLink;
            } else {
              filePreviewLink = fileOssLink;
            }

            let aliyunOssFileDto = {
              fileGuid: fileGuid,
              fileExtension: fileExtension,
              fileSize: dataObj.fileSize,
              fileAliyunName: fileGuid,
              fileOrigionName: dataObj.fileOrigionName,
              fileAliyunFullPath: aliyunFullPath,
              fileAliyunOssLink: fileOssLink,
              creationDate: new Date(),
              fileContentType: dataObj.fileContentType,
              filePreviewAddress: filePreviewLink,
              docType: dataObj.docType,
              version: dataObj.version,
            };
            console.log("aliyunOssFileDto222:", aliyunOssFileDto);

            let params = {
              figureDocummentId: figureDocumentId,
              aliyunOssFileDto: aliyunOssFileDto,
            };
            console.log("params:", params);

            uploadNewVersion(params)
              .then((res) => {
                console.log("uploadNewVersion", res);
                this.loading = false;
                this.$refs.refUpdateDoc.dataSource = [];
                this.$refs.refUpdateDoc.fileList = [];
                this.visibleNew = false;
                this.$message.success("上传新版成功!");
                this.getData();
              })
              .catch(() => {
                this.visibleNew = false;
                this.loading = false;
                this.$message.error("上传失败!");
              });
          })
          .catch(function (err) {
            console.log(err);
          });
      }

      // let params = {
      //   saveOrDownLoadFileInput: {
      //     fileAliyunFullPathArray: [dataObj.fileAliyunFullPath],
      //   },
      //   docType: dataObj.docType,
      //   figureDocummentId: this.figureDocumentId,
      // };

      // uploadNewVersion(params)
      //   .then((res) => {
      //     console.log("uploadNewVersion", res);
      //     this.visibleNew = false;
      //     this.$message.success("上传新版成功!");

      //     this.getData();
      //   })
      //   .catch(() => {
      //     this.visibleNew = false;
      //     this.$message.error("上传失败!");
      //   });
    },

    //上传成功回调
    whenUploadOk() {
      let params = {
        figureDocummentId: this.figureDocumentId,
        aliyunOssFileDto: this.$refs.refUpdateDoc.aliyunOssFileDto,
      };
      console.log("params:", params);

      uploadNewVersion(params)
        .then((res) => {
          console.log("uploadNewVersion", res);
          this.$refs.refUpdateDoc.clearClick();
          this.visibleNew = false;
          this.$message.success("上传新版成功!");
          this.getData();
        })
        .catch(() => {
          this.$refs.refUpdateDoc.loading = false;
          this.$message.error("上传失败!");
        });
    },

    toLog() {
      console.log("toLog");
      let figureDocumentId = this.figureDocumentId;
      let id = this.id;
      let edit = this.edit;
      let isFrom = this.isFrom;
      let params = {
        figureDocumentId,
        id,
        edit,
        isFrom,
      };

      this.$router.push({
        name: "FigureDocManageLog",
        params,
      });
    },

    toBack() {
      // let isFrom = this.isFrom;
      // if(isFrom == 'list' || isFrom == 'historyModal') {
      //   this.$router.push({
      //     name: "FigureDocManage",
      //   });
      // } else {
      //   this.$router.go(-1);
      // }
      this.$router.go(-1);
    },

    historyVersion() {
      this.visibleHistory = true;
    },

    getDocArr() {
      getAllFigureDocTypes().then((res) => {
        console.log("getDocType", res);

        this.arrDocTypes = res || [];
        localStorage.setItem("arrDocTypes", JSON.stringify(this.arrDocTypes));
        this.getObjDocType(this.arrDocTypes);
      });
    },

    getObjDocType(arr) {
      let _this = this;
      arr.forEach((item) => {
        _this.objDocType[item.id] = item.codeName;
      });
      console.log("objDocType", this.objDocType);
      localStorage.setItem("objDocType", JSON.stringify(this.objDocType));
    },

    whenEdit() {
      this.isEdit = true;
    },

    setLeftCollapse() {
      const lb = findLeftBar(this);
      let $parent = lb;
      do {
        $parent = $parent.$parent;
      } while (
        typeof $parent.collapsed === "undefined" &&
        $parent != this.$root
      );
      const rs = !this.isHiddenSideBar;
      $parent.collapsed = rs;
    },

    setRightCollapse() {
      this.isHiddenInfomation = !this.isHiddenInfomation;
    },
  },
  computed: {
    leftStyle() {
      if (this.isHiddenInfomation) {
        return "width:100%";
      }
      return "width:66%";
    },
    rightStyle() {
      if (this.isHiddenInfomation) {
        return "width: 0;overflow: hidden;visibility: hidden;display: block;";
      }
      return "width:33%;";
    },
    leftIcon() {
      return this.isHiddenSideBar ? "double-right" : "double-left";
    },
    rightIcon() {
      return this.isHiddenInfomation ? "double-left" : "double-right";
    },
    isHiddenSideBar() {
      const lb = findLeftBar(this);
      return lb ? lb.collapsed : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fff !important;
}

.head {
  text-align: center;
  position: relative;
  a {
    font-size: 16px;
    > i {
      padding-right: 8px;
    }
  }
  .back {
    position: absolute;
    right: 0;
    top: -8px;
  }
}
.table {
  display: table;
  width: calc(100% - 40px);
  height: calc(100% - 160px);
  min-height: 800px;
  padding: 0 !important;
  .table-cell {
    display: table-cell;
  }
  .left {
    position: relative;
    vertical-align: middle;
    padding: 2em;
    .collapse {
      width: 1em;
      text-align: left;
      position: absolute;
      top: calc(50% - 6em);
      padding: 5em 0;
      :hover {
        opacity: 0.8;
      }
    }
    .left-collapse {
      left: 0.5em;
    }
    .right-collapse {
      right: 0.5em;
    }
  }
  .right {
    padding: 1em 2em;
    vertical-align: top;
  }
  .back {
    position: absolute;
    right: 0;
    top: -8px;
  }
}
/**屏幕高度如果小于等于768,预览最小高度为600 */
@media screen and (max-height: 768px) {
  .table {
    min-height: 600px;
  }
}
</style>
